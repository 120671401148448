/* ------ SKETCHPLAY CUSTOM CSS ---------- */

/* .skp-card-header {
    background-color: #000;
} */

.skp-primary button { padding: 10px 50px; background-color: #000; box-shadow: none; border: 1px solid #000; border-radius: 5px; }
.skp-primary button:hover { background-color: #fff; border: 1px solid #000; border-radius: 5px; color: #000;  }
.skp-primary button:visited { background-color: #000; box-shadow: none; border: 1px solid #000; border-radius: 5px; }
.skp-primary button:active { background-color: #000; box-shadow: none; border: 1px solid #000; border-radius: 5px; }

.h4-big { font-size: 50px; color: blue; }